.headerCityDiv {
  position: relative;
  width: 100%;
}
.headerIllustration {
  width: 100%;
}
.headerOverlay {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerOverlayBlue {
  background-color: #1562ad;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.headerTilesOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.headerH1 {
  color: white !important;
  z-index: 10;
}
.cityDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
}
.cityDescription div {
  max-width: 800px;
}
.cityFirstIllustration {
  display: flex;
  align-items: center;
  justify-content: center;
}
