body {
  margin: 0;
  font-family: "Noto Sans", "Open Sans", "Segoe UI", "Oxygen", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2f4179 !important;
  background-color: #fff !important;
  text-align: left;
  font-weight: 600;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-weight: 900 !important;
  font-size: 70px !important;
  color: #2f4179;
}

h2 {
  font-size: 40px !important;
}

h3 {
  font-size: 26px !important;
}

h4 {
  font-size: 18px !important;
}

p {
  font-size: 20px !important;
}

.smallText {
  font-size: 14px !important;
}

.verticalAlign {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.noPadding {
  padding: 0 !important;
}

.padding {
  padding: 25px !important;
}

.smallPadding {
  padding: 5px !important;
}

.paddingTop {
  padding-top: 60px !important;
}

.bigPaddingTop {
  padding-top: 120px !important;
}

.smallPaddingTop {
  padding-top: 20px !important;
}

.bigPaddingBottom {
  padding-bottom: 120px !important;
}
.paddingBottom {
  padding-bottom: 60px !important;
}
.smallPaddingBottom {
  padding-bottom: 20px !important;
}

.paddingSides {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.smallPaddingSides {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.paddingSidesPx {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.marginTop {
  margin-top: 60px !important;
}

.bigMarginTop {
  margin-top: 120px !important;
}

.marginRight {
  margin-right: 20px !important;
}

.smallMarginTop {
  margin-top: 20px !important;
}

.smallAllMargin {
  margin: 20px;
}

.autoMargin {
  margin: auto !important;
}

.row {
  margin-left: auto !important;
  margin-right: auto !important;
}

.countNavbar {
  padding-top: 60px !important;
}

.width100 {
  width: 100% !important;
}

.height100 {
  height: 100vh;
}

.backgroundWhite {
  background-color: #fff !important;
}

.backgroundGrey {
  background-color: #e1e1e1 !important;
}

.backgroundDarkGrey {
  background-color: #333333 !important;
}

.backgroundDarkBlue {
  background-color: #2f4179 !important;
}
.backgroundBlue {
  background-color: #1562ad !important;
}

.colorWhite {
  color: #ffffff !important;
}

.colorGrey {
  color: #808080 !important;
}

.colorDarkGrey {
  color: #4d4d4d !important;
}

.colorBlack {
  color: #000000 !important;
}

.colorDarkBlue {
  color: #2f4179 !important;
}
.colorBlue {
  color: #3998e5 !important;
}

a:hover {
  color: #e81e79 !important;
}

.textBold {
  font-weight: 900 !important;
}

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.btnInactive {
  opacity: 0.5;
  cursor: default !important;
}

.btnMaru {
  z-index: 10 !important;
  background: rgb(246, 171, 59);
  background: linear-gradient(
    90deg,
    rgba(246, 171, 59, 1) 0%,
    rgba(232, 30, 121, 1) 100%
  );
  color: white !important;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  font-size: 20px !important;
}

.btnPurple {
  background: rgb(153, 0, 93);
  background: linear-gradient(
    90deg,
    rgba(153, 0, 93, 1) 0%,
    rgba(27, 20, 100, 1) 100%
  );
}

.btnWhite {
  background: #ffffff;
  color: rgba(232, 30, 121, 1) !important;
}

.btnOrange:hover {
  background: rgba(232, 30, 121, 1);
}

.smallBtn {
  font-size: 12px !important;
  padding: 5px 10px 5px 10px;
}

.bigBtn {
  font-size: 32px !important;
  font-weight: 600;
  padding: 14px 60px;
}

.cursorPointer {
  cursor: pointer;
}

.lastDiv {
  height: 100px;
}

.hideMobile {
  display: block;
}

.showMobile {
  display: none !important;
}

.apeLinks {
  font-size: 24px;
  font-weight: 900;
  color: #1562ad !important;
}
.apeLinks:hover {
  color: #000 !important;
}

@media only screen and (max-width: 768px) {
  .hideMobile {
    display: none !important;
  }

  .showMobile {
    display: block !important;
  }
}

@media only screen and (max-width: 960px) {
  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 26px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p {
    font-size: 18px !important;
  }
  .smallText {
    font-size: 10px !important;
  }

  .smallBtn {
    font-size: 12px !important;
    padding: 2px 5px 2px 5px;
  }
}
