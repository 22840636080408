.nef-pic {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.nef-pic img {
  width: 100% !important;
  height: fit-content !important;
  margin-top: -100px;
}
