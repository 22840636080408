.homeHeader {
  padding: 60px 0 0 0 !important;
}
.headerImage {
  width: 100% !important;
  margin: 0 auto !important;
}
.tileSectionBreak {
  width: 100% !important;
  margin: 0 auto !important;
}
.colDescription {
  display: flex;
  align-items: center;
  padding: 60px 20px !important;
}
.divDescription {
  margin: auto;
  max-width: 800px;
}
.rowSectionTitle {
  text-align: left;
  display: flex;
  align-items: flex-end;
}

.sectionApes {
  position: relative;
  padding: 0 !important;
}
.sectionApes img {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.sectionApes p {
  padding: 20px;
}
.sectionImage {
  padding: 0 !important;
}

.tileImg {
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto !important;
}

.formSearch {
  margin: 0 auto;
  text-align: center;
}
.searchButton {
  margin: 10px;
}

.rowGenerativeTiles {
  margin: 120px auto !important;
}
.generativeTilesDescription {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputID {
  margin-left: 20px !important;
}

.cityDiv {
  position: relative;
  width: 100%;
  padding: 10px !important;
}
.cityIllustration {
  width: 100%;
}
.cityOverlay {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.cityOverlay:hover {
  opacity: 1;
}
.cityOverlayBlue {
  background-color: #1562ad;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.tilesOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cityLink {
  color: white !important;
  font-size: 60px !important;
  font-weight: 900 !important;
  text-align: center !important;
  z-index: 10;
}

.rowApesTeam {
  margin-bottom: 120px !important;
}

.teamName:hover {
  color: black !important;
}
.divDownload {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.downloadButton {
  border: none;
  background-color: #1562ad;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  margin: 10px auto !important;
  transition: all 0.5s ease-in-out;
}
.downloadButton:hover {
  border: 1px solid #1562ad;
  background-color: #fff;
  color: #1562ad !important;
  text-decoration: none;
  padding: 10px 20px;
  margin: 10px auto !important;
}
