.customNavBarStyle {
  color: #2f4179 !important;
  background: none !important;
}

.topBar {
  padding: 5px;
  background: #fff !important;
  z-index: 15;
}

.navbar-brand img {
  height: 40px;
  padding-left: 20px;
}
.navbarName {
  float: right;
  line-height: 40px;
  margin-left: 10px;
}

.customNavBarStyle-links a {
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: 26px !important;
  letter-spacing: 1px;
  padding: 0px 20px !important;
  color: #2f4179 !important;
}

.customNavBarStyle-links a:hover {
  color: #3998e5 !important;
}

.socialIcon {
  cursor: pointer;
  width: 40px;
  margin: 5px 20px;
}

.navbarToggle-Icon {
  color: #808080 !important;
  font-size: 30px;
}

.navActive {
  color: #3998e5 !important;
  border-bottom: 2px #3998e5 solid;
  border-radius: 2px;
}

@media only screen and (max-width: 960px) {
  .webSummitBar img {
    height: 20px;
    margin: 0 5px;
  }

  .webSummitBar p {
    margin: 0 5px !important;
    font-size: 14px !important;
  }

  .customNavBarStyle-links {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .customNavBarStyle-links img {
    margin: 10px auto;
  }

  .customNavBarStyle-links a {
    margin: 14px auto !important;
  }
}
